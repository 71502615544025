<template>
  <div class="choice-date-hour">
    <div class="bloc-4">
        <div class="bloc-2">
            <label for="date">
                <h4>Date de la collecte*</h4>
                <el-select v-model="getForm.date" placeholder="Date" :class="{ 'errorInput': regex.date }" @select="getForm.time = null" :size="sz">
                    <el-option v-for="day in getPlanning" :key="day.day" :label="day.label + ' ' + day.day" :value="day.day" />
                </el-select>
            </label>
            <label for="time">
                <h4>Créneau horaire*</h4>
                <el-select v-model="getForm.time" :disabled="!dayIsSelected" :class="{ 'errorInput': regex.time }" placeholder="Créneau horaire" :size="sz">
                    <el-option label="15h/17h" :value="0" :key="0" :disabled="!checkFirstSlot"></el-option>
                    <el-option label="17h/19h" :value="1" :key="1" :disabled="!checkSecondSlot"></el-option>
                </el-select>
            </label>
        </div>
    </div>
    <div class="user-actions">
        <button v-if="getActiveStatus < 2" class="next" @click="nextCheck()">SUIVANT</button>
        <button v-if="getActiveStatus === 2" class="next" @click="next()">PASSER COMMANDE</button>
        <button v-if="getActiveStatus !== 3 && getActiveStatus !== 0" class="back" @click="$emit('emit-back')">RETOUR</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            sz: 'large',

            regex: {
                date: false,
                time: false
            }
        }
    },
  computed: {
        ...mapGetters({
            getActiveStatus: 'getActiveStatus',
            getForm: 'getForm',
            getPlanning: 'getPlanning'
        }),
      dayIsSelected() {
          if (this.getForm.date !== null) {
              return true;
          }
          return false;
      },
      checkFirstSlot() {
          if (this.getForm.date !== null) {
              const select_date = this.getPlanning.filter(day => day.day == this.getForm.date);

              if (select_date[0].slot_1517_0 === true) {
                  return true;
              } else {
                  return false;
              }
          } else {
              return false;
          }
      },
      checkSecondSlot() {
          if (this.getForm.date !== null) {
              const select_date = this.getPlanning.filter(day => day.day == this.getForm.date);

              if (select_date[0].slot_1719_1 === true) {
                  return true;
              } else {
                  return false;
              }
          } else {
              return false;
          }
      },
    },
    methods: {
        nextCheck() {
            //eslint-disable-next-line
            const date_format = /^[0-9]{1,2}[\/]{1}[0-9]{1,2}[\/]{1}[0-9]{4}$/;

            if (this.getForm.time !== 0 && this.getForm.time !== 1) {
                this.regex.time = true;
            } else {
                this.regex.time = false;
            }

            if (!date_format.test(this.getForm.date)) {
                this.regex.date = true;
            } else {
                this.regex.date = false;
            }

            this.next();
        },
        next() {
            if (this.regex.date === false && this.regex.time === false) {
                this.$store.commit('SET_ACTIVE', 2);
                window.scrollTo(0, 0);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.bloc-4,
.bloc-4 .bloc-2 {
    display: flex;
    align-items: center
}

.bloc-4 {
    width: 100%;
    max-width: 670px;
    margin: auto;
    flex-direction: column;
    
    .bloc-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        label {
            margin: 10px;

            h4 {
                    font-weight: 400;
                }
        }
    }
}

/* Responsive */
@media screen and (max-width: 520px) {

}
</style>