<template>
    <LineChart :chart-data="data" :options="options" />
</template>

<script>
import { LineChart } from 'vue-chart-3';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

import { mapGetters } from 'vuex';

export default {
    components: {
        LineChart
    },
    data() {
        return {
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Visiteurs uniques par jour',
                        data: [],
                        borderColor: '#000',
                        borderWidth: 1,
                        pointBackgroundColor: '#000',
                        pointHoverRadius: 5,
                    }
                ]
            },
            options: {
            responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
    watch: {
        getVisitors() {
            console.log('change');
        }
    },
    computed: {
        ...mapGetters({
            getVisitors: 'getVisitors',
        }),
    },
    mounted() {
        setTimeout(() => {
            if (this.getVisitors) {
                this.getVisitors.forEach(day => {
                    this.data.labels.push(day.day);
                    this.data.datasets[0].data.push(day.count);
                });
            }
        }, 1000);
    }
}
</script>
