<template>
  <div class="not-found">
    <h1>Oups, cette page n'existe pas</h1>
    <a href="https://malaverieadoree.fr/">Retourner sur la page d'accueil</a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.not-found {
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
        margin: 50px 0;
    }

    a {
        margin-bottom: 120px;
    }
}
</style>