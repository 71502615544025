<template>
  <div class="order-success">
    <h3>Merci pour votre commande !</h3>
    <p>Vous receverez prochainement un SMS pour vous confirmer le passage de votre commande.</p>
    <br>
    <h4>Des questions ?</h4>
    <p>
        <a href="tel:+33658105449">06.58.10.54.49</a>
    </p>
    <p style="margin: 15px;">ET/OU</p>
    <p>
        <a href="mailto:malaverieadoree@gmail.com">malaverieadoree@gmail.com</a>
    </p>
    <button @click="$router.push('/')">Retourner à l'accueil</button>
    <button class="new" @click="reload()">Repasser commande ?</button>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        reload() {
            location.reload();
        }
    }
}
</script>

<style scoped lang="scss">
.order-success {
    text-align: center;

    h3 {
        margin: 40px auto;
        background-color: #caa033;
        color: #FFF;
        width: 310px;
        padding: 15px 0;
        border-radius: 5px;
    }

    p {
        margin: 0 10px;

        a {
            color: #000;
            font-size: 17px;
        }
    }

    button {
        border: 0;
        cursor: pointer;
        padding: 10px 25px;
        margin: 10px;
        margin-top: 80px;
        color: #000;
    }

    .new {
        background-color: #caa033;
        color: #FFF;
    }
}
</style>