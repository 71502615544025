<template>
  <div class="order-choice">
    <div class="bloc-4">
        <div class="bloc-2">
            <label for="prestation">
                <h4>Type de collecte*</h4>
                <el-select v-model="getForm.prestation" multiple placeholder="Option de collecte" :class="{ 'errorInput': regex.prestation }" :size="sz">
                    <el-option label="OFFRE CLASSIQUE" :value="1" :key="1"></el-option>
                    <el-option label="OFFRE PREMIUM" :value="2" :key="2"></el-option>
                    <el-option label="OFFRE CHEMISES" :value="3" :key="3"></el-option>
                    <el-option label="COUETTE 1 PLACE" :value="4" :key="4"></el-option>
                    <el-option label="COUETTE 2 PLACES" :value="5" :key="5"></el-option>
                    <el-option label="OFFRE OREILLER" :value="6" :key="6"></el-option>
                    <el-option label="REPASSAGE LINGE FAMILIAL" :value="7" :key="7"></el-option>
                    <el-option label="REPASSAGE CHEMISES" :value="8" :key="8"></el-option>
                </el-select>
            </label>
            <!-- <label for="delivery">
                <h4>Délai de restitution*</h4>
                <el-select v-model="getForm.delivery_time" placeholder="Délai de restitution" :class="{ 'errorInput': regex.delivery_time }" :size="sz">
                    <el-option label="24h" :value="0" :key="0"></el-option>
                    <el-option label="48h" :value="1" :key="1"></el-option>
                    <el-option label="72h" :value="2" :key="2"></el-option>
                </el-select>
            </label> -->
        </div>
    </div>
    <div class="user-actions">
        <button v-if="getActiveStatus === 2" class="next" @click="nextCheck()">PASSER COMMANDE</button>
        <button v-if="getActiveStatus !== 3 && getActiveStatus !== 0" class="back" @click="$emit('emit-back')">RETOUR</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            sz: 'large',

            regex: {
                prestation: false,
                delivery_time: false
            }

        }
    },
    computed: {
        ...mapGetters({
            getActiveStatus: 'getActiveStatus',
            getForm: 'getForm'
        }),
    },
    methods: {
        nextCheck() {
            this.getForm.delivery_time = 2;
            //eslint-disable-next-line
            const prestation_format = /^[0-8]{1}$/;
            const delivery_time_format = /^[0-2]{1}$/;
            this.regex.prestation = false;

            if (!prestation_format.test(this.getForm.prestation)) {

                if (this.getForm.prestation.length > 0) {
                    let isWrongValueCount = 0;

                    for (const prestation of this.getForm.prestation) {
                        if (!prestation_format.test(prestation)) {
                            isWrongValueCount++;
                        }
                    }

                    if (isWrongValueCount !== 0) {
                        this.regex.prestation = true;
                    } else {
                        this.regex.prestation = false;
                    }
                } else {
                    this.regex.prestation = true;
                }
            } else {
                this.regex.prestation = false;
            }

            if (!delivery_time_format.test(this.getForm.delivery_time) || this.getForm.delivery_time === null) {
                this.regex.delivery_time = true;
            } else {
                this.regex.delivery_time = false;
            }

            this.next();
        },
        next() {
            if (this.regex.prestation === false && this.regex.delivery_time === false) {
                this.$store.dispatch('appointment', this.getForm);
                window.scrollTo(0, 0);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.bloc-4,
.bloc-4 .bloc-2 {
    display: flex;
    align-items: center;}

.bloc-4 {
    width: 100%;
    max-width: 670px;
    margin: auto;
    flex-direction: column;

    .bloc-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        label {
            margin: 10px;

            h4 {
                font-weight: 400;
            }
        }
    }
}
</style>