<template>
    <div class="professionnels">
        <div class="professionnels-bloc-title">
            <div class="text">
                <h1>SERVICE DE BLANCHISSERIE PROFESSIONNELLE</h1>
                <p>
                    Je vous propose un service de lavage, séchage, pliage et repassage de votre linge professionnel
                    (serviettes, linge de lit, tenues de travail etc.) en collecte et livraison dans un rayon de maxi 15KM
                    autour de Saint Laurent des Autels.
                </p>
                <p>
                    Je vous offre une solution simple, qualitative et sur-mesure. Qu’il s’agisse de commandes régulières
                    ou exceptionnelles, je vous propose des offres personnalisées et adaptées à vos besoins. 
                    Il n’y a aucun engagement à travailler avec moi, la satisfaction de ma clientèle demeure selon moi
                    le meilleur moyen de la fidéliser.
                </p>
            </div>
        </div>
        <div class="divider">
            <div>
                <h2>OBTENEZ UN DEVIS PERSONNALISE EN 48H</h2>
            </div>
        </div>
        <div class="professionnels-bloc-title">
            <div class="text">
                <h2 style="color: #caa033">VOUS ÊTES UN PROFESSIONNEL ET VOUS AVEZ DU LINGE A  FAIRE LAVER ?</h2>
                <h3 style="margin-top: 50px; color: #172162">Quelle que soit votre activité, profitez d’un service de blanchisserie adapté à vos besoins, flexible, rapide et efficace.</h3>
            </div>
        </div>
        <div class="card">
            <div class="card-box">
                <h3>SERVICE D’HÔTELLERIE D’HÉBERGEMENT ET GITES</h3>
                <div class="img-card">
                    <img src="@/assets/svg/bedroom.svg" alt="SERVICE D’HÔTELLERIE D’HÉBERGEMENT ET BNB" width="200">
                </div>
                <p>Pour la gestion du <b>linge de maison</b> : couettes, draps, serviettes, etc </p>
            </div>
            <div class="card-box">
                <h3>INSTITUTS DE BEAUTÉ, SPA, SALONS DE MASSAGES, SALONS DE COIFFURES</h3>
                <div class="img-card">
                    <img src="@/assets/svg/barber.svg" alt="INSTITUTS DE BEAUTÉ, SPA, SALONS DE MASSAGES, SALONS DE COIFFURES" width="260">
                </div>
                <p>L'entretien de vos serviettes et peignoirs joue un rôle essentiel dans <b>la 
                    qualité du soin apporté à vos clients.</b>
                    <br>
                    Je prends en charge votre linge
                    pour vous simplifier le quotidien
                    vous faire <b>gagner du temps</b> pour
                    vous consacrer pleinement à 
                    votre cœur de métier.
                </p>
            </div>
            <div class="card-box">
                <h3>PROFESSIONS LIBÉRALES ET PROFESSIONNELS DE SANTÉ</h3>
                <div class="img-card">
                    <img src="@/assets/svg/medical.svg" alt="PROFESSIONS LIBÉRALES ET PROFESSIONNELS DE SANTÉ" width="235">
                </div>
                <p>
                    Cabinets dentaires, soins paramédicaux,
                        ostéopathie, kinésithérapie, etc.
                    <br>
                    Vous avez besoins d’un <b>service de
                        qualité</b> pour laver, sécher, plier ou
                        repasser vos blouses et tenues de travail
                        Je vous <b>facilite la gestion de votre linge
                        professionnel</b> avec un service sur-mesure.
                </p>
            </div>
            <div class="card-box">
                <h3>TPE</h3>
                <div class="img-card">
                    <img src="@/assets/svg/factory.svg" alt="TPE" width="315">
                </div>
                <p>
                    Vous avez besoin d’un <b>service de
                    qualité</b> pour laver, sécher, plier 
                    ou repasser vos tenues de travail.
                    <br>
                    Je vous <b>facilite la gestion de 
                    votre linge professionnel</b> avec un
                    service sur-mesure.
                </p>
            </div>
            <div class="card-box">
                <h3>ASSOCIATIONS SPORTIVE</h3>
                <div class="img-card">
                    <img src="@/assets/svg/sport.svg" alt="ASSOCIATIONS SPORTIVE" width="202">
                </div>
                <p>
                    Sport collectif, vous avez sûrement un
                    certain volume de linge à faire LAVER !!
                    <br>
                    Je vous propose des <b>tarifs préférentiels</b>
                    pour la gestion de votre linge.
                    Profitez d’un <b>service de qualité</b>, simple 
                    et rapide.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.professionnels{
    max-width: 1200px;
    margin: auto;
}

h1 {
    color: #caa033;
    margin-bottom: 50px;
    text-align: center;
}

.text{
    width: 100%;
    text-align: center;
    
    p {
        margin: 10px;
    }
}
.divider{
width: 700px;
  padding: 25px 10px;
  background: #162061;
  text-align: center;
  margin: 70px auto;
  border-radius: 5px;
  color: #FFFFFF;

  div h2 {
    margin: 0;
  }
}

.professionnels-bloc-title{
    display: flex;
    flex-direction: row;
}

.card{
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    flex-wrap: wrap;
    max-width: 1000px;
    width: 100%;
}

.card-box{
    display: flex;
    flex-direction: column;
    width: 400px;
    background: #FFFFFF;
    padding: 40px 17px 0 17px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 10px 20px #16206115;
    margin: 25px auto;

    h3 {
        height: 50px;
        width: 300px;
        margin: 0 auto;
        margin-bottom: 30px;
        color: #caa033;
    }
}

.img-card{

    img {
        margin: 20px 0;
    }
}

@media screen and (max-width: 750px) {
    h1, h2 {
        margin-left: 10px;
        margin-right: 10px;
    }
    
    h1 {
        font-size: 20px;
    }

    .text h3 {
        font-weight: 400;
        margin: 10px;
    }
  .professionnels{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .divider {
    width: 300px;
    padding: 25px 0;
    margin-left: 15px;
    margin-right: 15px;

    h2 {
        font-size: 18px;
        margin: 0 10px;
    }
  }

  .img-card img {
    scale: .8;
  }

  .card-box {
    scale: .9;
    padding: 40px 7px 0 7px;
    margin: 5px auto;
  }

}
</style>