<template>
    <div class="login">
        <h1>Espace membre</h1>
        <p>Veuillez entrer votre identifiant et votre mot de passe pour accèder à votre espace personnel.</p>
        <div class="credentials">
            <label for="username">
                <h4>Identifiant</h4>
                <el-input autofocus name="username" autocomplete="username" v-model="credentials.username" :class="{ 'errorInput': regex.username }" placeholder="Mon identifiant personnel" />
            </label>
            <label for="password">
                <h4>Mot de passe</h4>
                <el-input type="password" name="password" autocomplete="password" v-model="credentials.password" :class="{ 'errorInput': regex.password }" placeholder="Mot de passe" />
            </label>
            <button class="login-btn" @click="checkValues()">Me connecter</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            credentials: {
                username: null,
                password: null
            },

            regex: {
                username: false,
                password: false
            }
        }
    },
    methods: {
        checkValues() {
            const pre_match = /^[a-zA-Z0-9-]{7,75}$/;

            if (!pre_match.test(this.credentials.username)) {
                this.regex.username = true;
            } else {
                this.regex.username = false;
            }

            if (!pre_match.test(this.credentials.password)) {
                this.regex.password = true;
            } else {
                this.regex.password = false;
            }

            this.login();
        },
        login() {
            if (this.regex.username === false && this.regex.password === false) {
                this.$store.dispatch('login', this.credentials);
            }
        }
    }
}
</script>

<style scoped lang="scss">
h1 {
    text-align: center;
}

p {
    text-align: center;
    max-width: 320px;
    margin: 10px auto;
}

.credentials {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: 50px auto;

    label {
        margin: 10px;
        width: 100%;

        h4 {
            font-weight: 400;
            margin-top: 5px;
        }
    }
}

.login-btn {
    border: none;
    background-color: #33ad33;
    color: #FFF;
    padding: 10px 45px;
    cursor: pointer;
    margin-top: 15px;

    &:hover {
        background-color: #4ac24a;
        transform: scale(1.02);
    }
}
</style>