<template>
    <footer>
        <div class="contain">
            <div class="mentions légales">
                <h5>Mention légales</h5>
                <p>SARL - Ma laverie ad'orée</p>
                <p>Capital social : 5000€</p>
                <a class="ml-ovh" href="https://www.ovhcloud.com/fr/about-us/" rel="noreferrer" target="_blank">Site hébergé chez OVH</a>
            </div>
            <div class="address">
                <div>
                    <h5>Adresse</h5>
                    <p>La croix voleau / Route du Fuilet</p>
                    <p>SAINT LAURENT DES AUTELS</p>
                    <p>49270 ORÉE D’ANJOU</p>
                    <p>
                        <a href="tel:+33658105449">06.58.10.54.49</a>
                    </p>
                    <p>
                        <a href="mailto:malaverieadoree@gmail.com">malaverieadoree@gmail.com</a>
                    </p>
                </div>
                <div class="img">
                    <a ref="noreferrer" target="_blank" href="https://www.google.com/maps/dir//Astikoto+Saint-Laurent-des-Autels,+La+Croix+Voleau,+49270+Or%C3%A9e-d'Anjou/@47.2848965,-1.1736298,16.25z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48060f88734a20ed:0x52e0da6845689cb1!2m2!1d-1.1778775!2d47.2851081">
                        <img src="@/assets/img/map.png" alt="Choisir la bonne offre" width="370">
                    </a>
                </div>
            </div>
            <div class="social-network">
                <h5>Retrouvez moi sur</h5>
                <a href="https://www.facebook.com/profile.php?id=100087850940783" ref="noreferrer" target="_blank">
                    <img src="@/assets/svg/facebook.svg" alt="Accèdez à ma page Facebook" width="25">
                    Facebook
                </a>
                <a href="https://instagram.com/karinefender?igshid=YmMyMTA2M2Y=" ref="noreferrer" target="_blank">
                    <img src="@/assets/svg/instagram.svg" alt="Accèdez à ma page Instagram" width="25">
                    Instagram
                </a>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    data() {
        return {

        }
    },
}
</script>

<style scoped lang="scss">
footer {
    background-color: #162061;
    color: #FFF;
}

.contain {
    display: flex;
    justify-content: space-around;
    padding: 50px 15px;
    max-width: 1400px;
    margin: auto;

    div {
        margin: 0 20px;
    }
}

.address, .social-network {
    display: flex;
}


.address div {
    display: flex;
    flex-direction: column;

    a {
        color: #FFF;
    }
}
.social-network {
    flex-direction: column;
    
    a {
        display: flex;
        align-items: center;
        color: white;
        text-align: center;
        margin: 5px 0;
        text-decoration: none;

        img {
            margin-right: 8px;
        }
    }
}

p {
    margin: 2px 0;
}

h5 {
    font-size: 17px;
}

.ml-ovh {
    color: #FFF;
    font-size: 13px;
}

/* Responsive */
@media screen and (max-width: 1200px) {
    .address {
        flex-direction: column;

        .img {
            margin-top: 15px;
            
            a img {
                width: 290px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .contain {
        flex-direction: column;
        align-items: flex-start;
    }

    .address {
        margin-left: 0!important;

        .img a img {
            width: 250px;
        }
    }

    .social-network {
        align-items: flex-start;
    }
}
</style>