import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

/* Pages */
import Home from '@/views/Home.vue'
import Professionals from '@/views/Professionals.vue'
import Price from '@/views/Price.vue'
import Running from '@/views/Running.vue'
import Order from '@/views/Order.vue'
import Cancel from '@/views/Cancel'
import PageNotFound from '@/views/404'

/* Auth */
import Login from '@/views/Login.vue'
import AuthHome from '@/views/auth/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/professionnels',
    name: 'Professionnels',
    component: Professionals
  },
  {
    path: '/tarifs',
    name: 'Tarifs',
    component: Price
  },
  {
    path: '/fonctionnement',
    name: 'Fonctionnement',
    component: Running
  },
  {
    path: '/passer-commande',
    name: 'Commande',
    component: Order
  },
  {
    path: '/passer-commande/annulation/:key',
    name: 'cancel',
    component: Cancel
  },
  {
    path: '/connexion',
    name: 'Connexion',
    component: Login
  },
  {
    path: '/auth/home',
    name: 'AuthHome',
    component: AuthHome,
    meta: {
      auth: true
    }
  },
  /* Page 404 */
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound,

  },
  {
    path: '/:catchAll(.*)',
    redirect: '/page-not-found'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.auth) {
    next('/login');
  } else {
    next();
  }
})

export default router
