<template>
  <div class="order">
    <h1>Passer une commande en ligne</h1>
    <div class="help">
      <a href="/fonctionnement">En savoir plus sur le fonctionnement de la plateforme</a>
    </div>
    <el-steps :active="getActiveStatus" finish-status="success" class="steps" :align-center="true">
      <el-step title="Vos informations" />
      <el-step title="Créneau de collecte" />
      <el-step title="Choix de la prestation" />
    </el-steps>
    <form onsubmit="return false">
      <CustomerInformations v-if="getActiveStatus === 0" @emit-back="back()"></CustomerInformations>
      <ChoiceDateHour v-if="getActiveStatus === 1" @emit-back="back()"></ChoiceDateHour>
      <OrderChoice v-if="getActiveStatus === 2" @emit-back="back()"></OrderChoice>
      <OrderSuccess v-if="getActiveStatus === 3" @emit-back="back()"></OrderSuccess>
    </form>
  </div>
</template>

<script>
import CustomerInformations from '@/components/form/customer_informations.vue';
import ChoiceDateHour from '@/components/form/choice_date_hour.vue';
import OrderChoice from '@/components/form/order_choice.vue';
import OrderSuccess from '@/components/form/order_success.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CustomerInformations,
    ChoiceDateHour,
    OrderChoice,
    OrderSuccess
  },
  data() {
    return {
      sz: 'large',
    }
  },
  computed: {
    ...mapGetters({
      getActiveStatus: 'getActiveStatus',
    }),
  },
  methods: {
    back() {
      this.$store.commit('SET_ACTIVE', this.getActiveStatus - 1);
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped lang="scss">
.order {
  padding-bottom: 70px;
}

h1 {
  text-align: center;
  color: #caa033;
  margin: 50px 10px;
}

.help {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 40px;

  a{
    color: #000;
    text-align: center;
    margin: 0 10px;
  }
}

.steps {
  margin: 20px auto;
  max-width: 1200px;
}

/* Formulaire */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  padding-top: 0;
  margin-bottom: 50px;
}

/* Responsive */
@media screen and (max-width: 520px) {
  .steps {
    display: none;
  }
}
</style>