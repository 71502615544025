import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index'
import { ElMessage } from 'element-plus'

export default createStore({
  state: {
    /* Form data */
    form : {
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      address: {
          address: null,
          zip: null,
          city: null
      },
      date: null,
      time: null,
      delivery_time: null,
      prestation: null,
    },

    /* Step form */
    active: 0,

    /* Planning data */
    planning: null,
    /* Auth variables */
    auth: false,
    appointments: [],

    /* Stats */
    visitors: []
  },
  getters: {
    getActiveStatus (state) {
        return state.active;
    },
    getForm (state) {
        return state.form;
    },
    getPlanning (state) {
        return state.planning;
    },
    getAppointments (state) {
        return state.appointments;
    },
    getVisitors (state) {
        return state.visitors;
    },
  },
  mutations: {
    /* SET STEP FORM */
    SET_ACTIVE(currentState, active) {
        currentState.active = active;
    },
    /* SET DAYS AVAILABLES - PLANNING */
    SET_DAYS_PLANNING(currentState, data) {
      currentState.planning = data;
    },
    /* Auth section */
    STORAGE_TOKEN(currentState, token) {
        localStorage.setItem('token', token[0]);

        // const d = new Date();
        // d.setTime(d.getTime() + 48*60*60*1000);
        // const expires = '; expires=' + d.toUTCString();
        // document.cookie = 'token=' + token[1] + expires + '; path=/;';
    },
    SET_AUTH(currentState, state) {
        currentState.auth = state;
    },
    SET_ALL_APPOINTMENTS(currentState, appointments) {
      currentState.appointments = [];
      currentState.appointments = appointments;
    },
    SET_VISITORS(currentState, visitors) {
      currentState.visitors = [];
      currentState.visitors = visitors;
    },
    STORE_NOTHING() { }
  },
  actions: {
    /* Public routes */
    getPlanning({ commit }) {
        axios.get(process.env.VUE_APP_BASE_URL + 'api/appointments', { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            if (res.data.planning) {
              commit('SET_DAYS_PLANNING', res.data.planning);
            }
          })
          .catch(() => {
              ElMessage({
                  message: 'Impossible de charger le planning. Ré-essayez plus tard',
                  type: 'error',
                  grouping: true,
                  duration: 6000
              })
          })
    },
    visitors({ commit }) {
      commit('STORE_NOTHING');
      axios.get(process.env.VUE_APP_BASE_URL + 'api/visitors', { headers: { 'Content-Type': 'application/json' } })
        .then(() => { })
    },
    statistiquesVisitors({ commit }) {
      axios.get(process.env.VUE_APP_BASE_URL + 'api/auth/visitors', { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
        .then((res) => {
          commit('SET_VISITORS', res.data?.visitors);
        })
    },
    appointment({ commit }, payload) {
        axios.post(process.env.VUE_APP_BASE_URL + 'api/appointment', payload, { headers: { 'Content-Type': 'application/json' } })
          .then(() => {
            commit('SET_ACTIVE', 3);
              ElMessage({
                  message: 'Votre commande a bien été envoyée. Vous serez bientôt contacté',
                  type: 'success',
                  grouping: false,
                  duration: 10000
              })
          })
          .catch((e) => {
              ElMessage({
                  message: e.response.data.errors ? 'Error : ' + e.response.data.errors : e.response.data.message ? e.response.data.message : 'Une erreur est survenue',
                  type: 'error',
                  grouping: true,
                  duration: 6000
              })
          })
    },
    /* Auth section */
    login({ commit, dispatch }, payload) {
        axios.post(process.env.VUE_APP_BASE_URL + 'api/login', payload, { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            if (res.data.token) {
              commit('STORAGE_TOKEN', res.data.token);
              commit('SET_AUTH', true);
              dispatch('getAppointments');
              dispatch('statistiquesVisitors');
              setInterval(() => {
                dispatch('getAppointments');
                dispatch('statistiquesVisitors');
              }, 120000);
              router.push('/auth/home');
              ElMessage({
                  message: 'Connexion réussie',
                  type: 'success',
                  grouping: false,
                  duration: 5000
              })
            }
          })
          .catch((e) => {
              ElMessage({
                  message: e.response.data.errors ? 'Error : ' + e.response.data.errors : e.response.data.message ? e.response.data.message : 'Une erreur est survenue',
                  type: 'error',
                  grouping: true,
                  duration: 6000
              })
          })
    },
    getAppointments({ commit }) {
        axios.get(process.env.VUE_APP_BASE_URL + 'api/auth/appointments', { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
          .then((res) => {
            if (res.data.appointments) {
              commit('SET_ALL_APPOINTMENTS', res.data.appointments);
            }
          })
          .catch(() => {
              ElMessage({
                  message: 'Impossible de charger les rendez-vous. Contactez le service client si le problème persiste',
                  type: 'error',
                  grouping: true,
                  duration: 6000
              })
          })
    },
    changeProcessed({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      axios.put(process.env.VUE_APP_BASE_URL + 'api/auth/appointment/processed/' + payload.id + '/' + payload.type, payload, { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
        .then((res) => {
          if (res) {
            ElMessage({
                message: 'L\'état du rendez-vous a bien été modifié',
                type: 'success',
                grouping: false,
                duration: 5000
            })
            dispatch('getAppointments');
          }
        })
        .catch(() => {
            ElMessage({
                message: 'Un problème est survenu lors du changement d\'état du rendez-vous',
                type: 'error',
                grouping: true,
                duration: 6000
            })
        })
    },
    archive({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      axios.put(process.env.VUE_APP_BASE_URL + 'api/auth/appointment/' + payload.id, payload, { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
        .then((res) => {
          if (res) {
            ElMessage({
                message: 'Ce rendez-vous a été archivé',
                type: 'success',
                grouping: false,
                duration: 5000
            })
            dispatch('getAppointments');
          }
        })
        .catch(() => {
            ElMessage({
                message: 'Un problème est survenu lors de l\'archivage du rendez-vous',
                type: 'error',
                grouping: true,
                duration: 6000
            })
        })
    },
    deleteSlot({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      axios.delete(process.env.VUE_APP_BASE_URL + 'api/auth/appointment/' + payload.id, { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
        .then((res) => {
          if (res) {
            ElMessage({
                message: 'Ce rendez-vous a été supprimé',
                type: 'success',
                grouping: false,
                duration: 5000
            })
            dispatch('getAppointments');
          }
        })
        .catch(() => {
            ElMessage({
                message: 'Un problème est survenu lors de la suppression du rendez-vous',
                type: 'error',
                grouping: true,
                duration: 6000
            })
        })
    },
    /* Cancel appointment */
    cancelAppointment({ commit }, payload) {
      commit('STORE_NOTHING');
      axios.delete(process.env.VUE_APP_BASE_URL + 'api/appointment/' + payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.message) {
            ElMessage({
                message: res.data.message,
                type: 'success',
                grouping: false,
                duration: 5000
            })
            router.push('/');
          }
        })
        .catch((e) => {
            ElMessage({
                message: e.response.data.errors ? 'Error : ' + e.response.data.errors : e.response.data.message ? e.response.data.message : 'Une erreur est survenue',
                type: 'error',
                grouping: true,
                duration: 6000
            })
        })
    },
  },
  modules: {
  }
})
