<template>
    <div class="price">
      <div class="price-bloc-title">
        <h1>OFFRES ET PRIX</h1>
        <h2>Confiez moi votre linge et récupérez le propre en 72H maxi (jour ouvré)</h2>
      </div>
      <div class="offer-details">
        <h3 class="offer-details-title">MES OFFRES LAVAGE LINGE FAMILIAL</h3>
        <p class="offer-details-para">Vêtements - Draps - Serviettes - Chemises</p>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>Offre BASIC</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage</p>
              <p>3,50 € le kilo si - de 5KG</p>
              <p>3,00 € le kilo si + de 5KG</p>
            </div>
            <div class="card-body">
              <p>
                Vous n’avez plus besoin d’attendre à la laverie <br> Déposez votre linge au local ou
                 demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et remis à plat.</b><br>
                Il ne vous reste plus qu’à remettre les chaussettes par paire, plier et ranger votre linge
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre CLASSIQUE</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + pliage</p>
              <p>5,00 € le kilo si - de 5 KG</p>
              <p>4,50 € le kilo si + de 5 KG</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre haut de gamme !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et plié.</b><br>
                Il ne vous reste plus qu’à le ranger dans votre armoire !
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title premium">
              <h3>Offre PREMIUM</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Repassage</p>
              <p>8,00 € le kilo si - de 5KG</p>
              <p>7,00 € le kilo si + de 5KG</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre sur-mesure !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et repassé.</b><br>
                Il ne vous reste plus qu’à le ranger dans votre armoire !
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre CHEMISES</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Repassage</p>
              <p>4,00 €/pièce</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre haut de gamme !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et elles vous sont restituées 
                <b>lavées, séchées, repassées mises sur cintres.</b><br>
                Il ne vous reste plus qu’à les ranger dans votre armoire !
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">MES OFFRES COUETTES/OREILLER</h3>
        <h4>Le saviez-vous ?</h4>
        <p class="offer-details-para">Une couette et des oreillers lavés régulièrement améliorent la qualité de votre sommeil !</p>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>COUETTE SIMPLE (1 place)</h3>
              <h4>10,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Pliage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre couette au local ou demandez une collecte/livraison, et elle vous est 
                restituée <b>lavée, séchée, pliée</b>.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>COUETTE DOUBLE (2 places)</h3>
              <h4>20,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Pliage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre couette au local ou demandez une collecte/livraison, et elle vous est
                restituée <b>lavée, séchée, pliée</b>.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>OREILLER</h3>
              <h4>5,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre oreiller au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché</b>.
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">MES OFFRES REPASSAGE</h3>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>REPASSAGE LINGE FAMILIAL</h3>
            </div>
            <div class="card-header">
              <p>* Hors chemises/chemisiers</p>
              <p>6,00 € le kilo si - de 5KG</p>
              <p>5,00 € le kilo si + de 5KG</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre linge dans un sac ou bac à linge au local ou demandez 
                une collecte/livraison et il vous est restitué dans son contenant.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>REPASSAGE CHEMISES</h3>
            </div>
            <div class="card-header">
              <p>Pour 5 chemises propre et sèche</p>
              <p>2,50 €/pièce</p>
            </div>
            <div class="card-body">
              <p>
                Déposez vos chemises sur cintres au local ou demandez une collecte/livraison 
                et elles vous sont restituées sur leurs cintres.
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">MES OFFRES COLLECTE/LIVRAISON</h3>
        <img src="@/assets/svg/order_delivery.svg" alt="MES OFFRES COLLECTE/LIVRAISON" width="180">
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>Offre STANDARD 72H</h3>
              <h4>2,50 €</h4>
            </div>
            <div class="card-header">
              <p>Collecte au jour choisi / disponible.</p>
              <p>Livraison maxi 3 jours après.</p>
            </div>
            <div class="card-body">
              <p>
                Montant à ajouter à la prestation choisie.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre RAPIDE 48H</h3>
              <h4>3,50 €</h4>
            </div>
            <div class="card-header">
              <p>Collecte au jour choisi / disponible.</p>
              <p>Livraison maxi 2 jours après..</p>
            </div>
            <div class="card-body">
              <p>
                Montant à ajouter à la prestation choisie.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre EXPRESS 24H</h3>
              <h4>4,50 €</h4>
            </div>
            <div class="card-header">
              <p>Collecte au jour choisi / disponible.</p>
              <p>Livraison dans la journée ou maxi 1 jour après.</p>
            </div>
            <div class="card-body">
              <p>
                Montant à ajouter à la prestation choisie.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.price-bloc-title {

  h1, h2 {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  h1 {
    color: #caa033;
  }
  
  h2 {
    color: #162061;
    margin-bottom: 50px;
  }
}

.offer-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  .offer-details-title {
      background-color: #caa033;
      color: #FFF;
      padding: 10px 20px;
      border-radius: 3px;
      margin: 30px 10px;
      width: 650px;
      text-align: center;
    }

    .offer-details-para {
      margin: 0 15px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  
  .cards {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    margin-top: 10px;
    flex-wrap: wrap;
    max-width: 1300px;
    
  .card {
    width: 400px;
    box-shadow: 0 10px 20px #16206115;
    text-align: center;
    border-radius: 5px;
    margin: 15px;
    
    .card-title {
      background-color: #162061;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      color: #FFF;
      padding: 10px;
      
      h3 {
        margin-top: 0;
        margin-bottom: 7px;
      }
      
      h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
      }
    }
    
    .premium {
      background-color: #caa033;
    }

    .card-header {
      padding: 10px;
      
      p {
        margin: 0;
      }
    }

    .card-body {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 750px) {
  .offer-details-title {
      width: 350px!important;
    }
}

@media screen and (max-width: 450px) {
  .offer-details-title {
    width: 270px !important;
  }
  
  .card {
    width: 290px!important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
}
</style>