<template>
  <Navigation></Navigation>
  <router-view/>
  <Footer></Footer>
  <p class="cr">Karine Fender - MA LAVERIE AD’ORÉE - 2023</p>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    Navigation,
    Footer
  },
  data() {
    return {}
  },
  created() {
    this.$store.dispatch('visitors');
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: Raleway;
  src: url("./assets/font/raleway/Raleway-VariableFont_wght.ttf") format('TrueType')
}

body {
  font-family: Raleway;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

* {
  scroll-behavior: smooth;
  transition: all .3s;
}

.cr {
  background-color: #162061;
  color: #FFF;
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
}

h1 {
  margin-top: 50px;
}

p {
  letter-spacing: .6px;
  line-height: 23px;
}

/* Scroll bar */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #162061;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #4a5dd6;
}

/* Error and Succes inputs */
.errorInput {
  border: 2px solid var(--el-color-warning) !important;
  background-color: var(--el-color-warning-light-9);
  border-radius: 6px;
}

/* Form passer commande */
.user-actions {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  flex-wrap: wrap-reverse;

  button {
    margin: 10px;
  }
}

.next,
.back {
  border: none;
  background-color: #33ad33;
  color: #FFF;
  padding: 10px 45px;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    background-color: #4ac24a;
    transform: scale(1.02);
  }
}

.back {
  background-color: #bbbbbb;

  &:hover {
    background-color: #d3d3d3;
    transform: scale(1.02);
  }
}
</style>
