<template>
  <div class="customer-informations">
    <div class="bloc-4">
        <div class="bloc-2">
            <label for="prenom">
                <h4>Prénom*</h4>
                <el-input autofocus v-model="getForm.firstname" :class="{ 'errorInput': regex.firstname }" placeholder="Jean" :size="sz" />
            </label>
            <label for="nom">
                <h4>Nom*</h4>
                <el-input v-model="getForm.lastname" :class="{ 'errorInput': regex.lastname }" placeholder="Dupont" :size="sz" />
            </label>
        </div>
        <div class="bloc-2">
            <label for="email">
                <h4>Email*</h4>
                <el-input v-model="getForm.email" type="email" :class="{ 'errorInput': regex.email }" placeholder="malaverieadoree@gmail.com" :size="sz" />
            </label>
            <label for="telephone">
                <h4>Numéro de téléphone*</h4>
                <el-input maxlength="10" v-model="getForm.phone" type="tel" :class="{ 'errorInput': regex.phone }" placeholder="0658105449" :size="sz" />
            </label>
        </div>
        <div class="bloc-2">
            <label for="ville">
                <h4>Commune*</h4>
                <el-input v-model="getForm.address.city" :class="{ 'errorInput': regex.address.city }" placeholder="Ville" :size="sz" />
            </label>
            <label for="code_postal">
                <h4>Code postal*</h4>
                <el-input maxlength="5" v-model="getForm.address.zip" :class="{ 'errorInput': regex.address.zip }" placeholder="Code postal" :size="sz" />
            </label>
            <label for="rue">
                <h4>N° et nom de rue*</h4>
                <el-input v-model="getForm.address.address" :class="{ 'errorInput': regex.address.address }" placeholder="N° et rue" :size="sz" />
            </label>
        </div>
    </div>
    <p style="text-align: center;"><b>*</b> Ces informations sont obligatoires</p>
    <div class="user-actions">
        <button v-if="getActiveStatus < 2" class="next" @click="checkAllValues()">SUIVANT</button>
        <button v-if="getActiveStatus === 2" class="next" @click="next()">PASSER COMMANDE</button>
        <button v-if="getActiveStatus !== 3 && getActiveStatus !== 0" class="back" @click="$emit('emit-back')">RETOUR</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            sz: 'large',

            regex: {
                firstname: false,
                lastname: false,
                email: false,
                phone: false,
                address: {
                    address: false,
                    zip: false,
                    city: false
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            getActiveStatus: 'getActiveStatus',
            getForm: 'getForm'
        }),
    },
    methods: {
        checkAllValues() {
            this.checkFirstnameLastname();
            this.checkEmail();
            this.checkPhone();
            this.checkCity();
            this.checkAddress();
            this.checkZip();

            this.acceptValues();
        },
        checkFirstnameLastname() {
            const regex_firstname_lastname = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%&*(){}|~<>;:[\]]{2,50}$/;

            if (!regex_firstname_lastname.test(this.getForm.firstname) || this.getForm.firstname == null) {
                this.regex.firstname = true;
            } else {
                this.regex.firstname = false;
            }

            if (!regex_firstname_lastname.test(this.getForm.lastname) || this.getForm.lastname == null) {
                this.regex.lastname = true;
            } else {
                this.regex.lastname = false;
            }
        },
        checkEmail() {
            //eslint-disable-next-line
            const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!regex_email.test(this.getForm.email) || this.getForm.email == null) {
                this.regex.email = true;
            } else {
                this.regex.email = false;
            }
        },
        checkPhone() {
            const regex_phone = /^[0-9]{8,13}$/;

            if (!regex_phone.test(this.getForm.phone)) {
                this.regex.phone = true;
            } else {
                this.regex.phone = false;
            }
        },
        checkCity() {
            const regex_city = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,50}$/;

            if (!regex_city.test(this.getForm.address.city) || this.getForm.address.city == null) {
                this.regex.address.city = true;
            } else {
                this.regex.address.city = false;
            }
        },
        checkAddress() {
            const regex_address = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,80}$/;

            if (!regex_address.test(this.getForm.address.address) || this.getForm.address.address == null) {
                this.regex.address.address = true;
            } else {
                this.regex.address.address = false;
            } 
        },
        checkZip() {
            const regex_zip = /^[0-9]{4,6}$/;

            if (!regex_zip.test(this.getForm.address.zip) || this.getForm.address.zip == null) {
                this.regex.address.zip = true;
            } else {
                this.regex.address.zip = false;
            } 
        },
        acceptValues() {
            if (this.regex.firstname === false && this.regex.lastname === false &&
                this.regex.email === false && this.regex.phone === false &&
                this.regex.address.address === false && this.regex.address.zip === false &&
                this.regex.address.city === false) {

                this.$store.dispatch('getPlanning');
                this.$store.commit('SET_ACTIVE', 1);
                window.scrollTo(0, 0);
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>