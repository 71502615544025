<template>
    <div class="auth-home">
        <h1>Gestion des commandes</h1>
        <div class="search-bar">
            <el-input v-model="search" class="search" size="large" placeholder="Rechercher un client (nom)" clearable />
            <el-button type="info" class="refresh" @click="$store.dispatch('getAppointments'), $store.dispatch('statistiquesVisitors')">Rafraîchir</el-button>
        </div>
        <div class="appointments">
            <el-table :data="filterSearch" class="table">
                <el-table-column label="Enregistrement" prop="created_at" width="140px" />
                <el-table-column label="Nom/Prénom" width="210px">
                    <template #default="scope">
                        <span>{{ scope.row.lastname.toUpperCase() + ' ' + scope.row.firstname }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Réservation" prop="day" sortable width="130px">
                    <template #default="scope">
                        <span>{{ scope.row.day }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Créneau" prop="slots" sortable>
                    <template #default="scope">
                        <el-button v-if="scope.row.slots === 0" type="primary" size="small" plain>{{ transformSlot(scope.row.slots) }}</el-button>
                        <el-button v-if="scope.row.slots === 1" type="info" size="small" plain>{{ transformSlot(scope.row.slots) }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="Livré ?" prop="is_processed" sortable>
                    <template #default="scope">
                        <el-button v-if="scope.row.is_processed === 0" size="small" type="info" @click="archive(scope.row.id)">NON</el-button>
                        <el-button  v-if="scope.row.is_processed === 1" size="small" type="success" @click="archive(scope.row.id)">OUI</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="right">
                    <template #default="scope">
                        <el-button size="small" type="warning" @click="dialog_archive_slot = true, dialog_archive_slot_data = scope.row.id">Archiver</el-button>
                        <!-- scope.$index -->
                        <el-button size="small" @click="setDialogDetailsData(scope.row)">Details</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center" class="pagination-dashboard">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize"
                    :total="total">
                </el-pagination>
                <el-select v-model="pageSize" size="small" placeholder="Items per page">
                    <el-option :label="10" :value="10" />
                    <el-option :label="25" :value="25" />
                    <el-option :label="50" :value="50" />
                    <el-option :label="100" :value="100" />
                    <el-option :label="200" :value="200" />
                </el-select>
            </div>
            <el-dialog v-if="dialog_details" v-model="dialog_details" :title="dialog_details_data.lastname + ' ' + dialog_details_data.firstname" width="400px">
                <div class="dialog-details">
                    <p><b>DATE PRESTATION : </b> {{ dialog_details_data.day }}</p>
                    <p><b>CRENEAU PRESTATION : </b> {{ transformSlot(dialog_details_data.slots) }}</p>
                    <p><b>TYPE PRESTATION : </b> {{ transformPrestation(dialog_details_data.prestation) }}</p>
                    <p><b>LIVRAISON : </b> {{ transformDeliveryTime(dialog_details_data.delivery_time) }}</p>
                    <el-divider />
                    <p><b>ADRESSE : </b> {{ dialog_details_data.address + ', ' + dialog_details_data.zip + ' ' + dialog_details_data.city  }}</p>
                    <p><b>EMAIL : </b> {{ dialog_details_data.email }}</p>
                    <p><b>TELEPHONE : </b> {{ dialog_details_data.phone }}</p>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="danger" @click="dialog_delete_slot = true">
                            <el-icon>
                                <Delete />
                            </el-icon>
                        </el-button>
                        <el-button v-if="dialog_details_data.is_processed === 0" type="success" @click="changeProcessed(1)">Marquer comme livré</el-button>
                        <el-button v-else type="danger" @click="changeProcessed(0)">Marquer comme non livré</el-button>
                        <el-button type="info" @click="dialog_details = false">OK</el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-if="dialog_archive_slot" v-model="dialog_archive_slot" width="400px">
                <span>Etes-vous sûr de vouloir archiver ce rendez-vous ?</span>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="info" @click="dialog_archive_slot = false">Annuler</el-button>
                        <el-button type="danger" @click="archiveSlot(dialog_archive_slot_data)">Oui</el-button>
                    </span>
                </template>
            </el-dialog>
            <el-dialog v-if="dialog_delete_slot" v-model="dialog_delete_slot" width="400px">
                <span>Etes-vous sûr de vouloir supprimer ce rendez-vous ?</span>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="info" @click="dialog_delete_slot = false">Annuler</el-button>
                        <el-button type="danger" @click="deleteSlot()">Oui</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <div class="statistiques">
            <visitors></visitors>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Delete } from '@element-plus/icons-vue'
import visitors from '@/components/auth/visitors.vue';

export default {

    components: {
        Delete,
        visitors
    },
    data() {
        return {
            dialog_details: false,
            dialog_details_data: null,
            search: '',
            page: 1,
            pageSize: 10,
            total: 0,
            filter_progress: null,

            dialog_delete_slot: false,
            dialog_archive_slot: false,
            dialog_archive_slot_data: null,

        }
    },
    computed: {
        ...mapGetters({
            getAppointments: 'getAppointments',
        }),
        filterSearch() {
            if (this.getAppointments.length !== 0) {
                const filter_progress = this.getAppointments.filter(data => data.lastname.toLowerCase().includes(this.search.toLowerCase()));
                this.setFilterProgress(filter_progress);

                const total = this.getAppointments.length;
                this.setTotal(total);

                return this.filter_progress.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
            } else {
                return [];
            }
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        setFilterProgress(data) {
            this.filter_progress = data;
        },
        setTotal(data) {
            this.total = data;
        },
        transformSlot(slot) {
            if (slot === 0) {
                return '15h/17h';
            } else if (slot === 1) {
                return '17h/19h';
            }
        },
        transformPrestation(prestation) {
            const typeof_prestation = typeof prestation;
            const prestation_list = [
                "OFFRE BASIC",
                "OFFRE CLASSIQUE",
                "OFFRE PREMIUM",
                "OFFRE CHEMISES",
                "COUETTE 1 PLACE",
                "COUETTE 2 PLACES",
                "OFFRE OREILLER",
                "REPASSAGE LINGE FAMILIAL",
                "REPASSAGE CHEMISES"
            ]
            if (typeof_prestation === 'number') {
                return prestation_list[prestation];
            } else if (typeof_prestation === 'object') {
                const search_prestation = [];
                for (const key in prestation) {
                    search_prestation.push(prestation_list[key]);
                }
                return search_prestation;
            }
        },
        transformDeliveryTime(time) {
            switch (time) {
                case 0:
                    return '24H';
                case 1:
                    return '48H';
                case 2:
                    return '72H';
                default:
                    return 'N/A';
            }
        },
        setDialogDetailsData(data) {
            this.dialog_details_data = data;
            this.dialog_details = true;
        },
        async archiveSlot(id) {
            const payload = {
                id: id
            }

            await this.$store.dispatch('archive', payload).then(() => {
                this.dialog_archive_slot = false;
                this.dialog_archive_slot_data = null;
            })
        },
        async deleteSlot() {
            const payload = {
                id: this.dialog_details_data.id
            }

            await this.$store.dispatch('deleteSlot', payload).then(() => {
                this.dialog_delete_slot = false;
                this.dialog_details = false;
                this.dialog_details_data = null;
            })        },
        async changeProcessed(type) {
            const payload = {
                id: this.dialog_details_data.id,
                type: type
            }

            await this.$store.dispatch('changeProcessed', payload).then(() => {
                this.dialog_details = false;
                this.dialog_details_data = null;
            })
        }
    }
}
</script>

<style scoped lang="scss">
.auth-home {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    h1 {
        text-align: center;
    }

    .search-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 350px;
        width: 100%;
        flex-wrap: wrap;
        margin: auto;
        margin-top: 25px;
        
        .search {
            margin: auto;
            max-width: 240px;
        }
    }
}

.appointments {

    .pagination-dashboard {
        display: flex;
        align-items: center;
        margin-top: 25px;
    }
}

.table {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    margin-top: 50px;
}

.dialog-details p {
    word-break: keep-all;
}

.statistiques {
    margin: 0 20px;
}
</style>