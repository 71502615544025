<template>
  <div class="home">
    <div class="bckgrnd">
      <div class="home-picture">
        <h1>MA LAVERIE AD’OREE, UN SERVICE ARTISANAL DE PROXIMITÉ,
        MODERNE ET PRATIQUE. <br>POUR LES PARTICULIERS, LES PROFESSIONNELS, LES GITES ET LES ASSOCIATIONS.</h1>
        <div class="subtitle">
          <h2>2 possibilités pour la prise en charge de votre linge</h2>
        </div>
        <div class="home-picture-infos">
          <div class="home-address">
            <div class="bloc-1">
              <h3>En dépôt au local</h3>
              <p>LUNDI 8H30 à 12H30</p>
              <p>MARDI 8H30 à 12H30 et 15H à 19H</p>
              <p>MERCREDI 8H30 à 12H00</p>
              <p>JEUDI 8H30 à 12H30 et 15H à 19H</p>
              <p>VENDREDI 8H30 à 12H30</p>
              <br>
              <p>à côté d’Astikoto</p>
              <p>Route du Fuilet</p>
              <p>à Saint Laurent des Autels</p>
              <p>49270 OREE D’ANJOU</p>
            </div>
            <div class="home-or">
              <span>OU</span>
            </div>
            <div class="bloc-2">
              <h3>En collecte/livraison</h3>
              <p>A votre domicile ou</p>
              <p>sur votre lieu de travail</p>
              <p>Entrez votre adresse</p>
              <p>Choisissez votre jour de collecte</p>
              <p>Choisissez votre créneau</p>
              <p>Choisissez le délai de restitution</p>
              <p>24h ou 48h ou 72h</p>
              <p>Choisissez l’offre (voir tarif)</p>
            </div>
          </div>
        </div>
        <div class="order-bloc">
          <button class="btn-order" @click="$router.push('passer-commande')">PASSER COMMANDE</button>
        </div>
      </div>
    </div>
    <div class="price">
      <div class="price-bloc-title">
        <h2>OFFRES ET PRIX</h2>
      </div>
      <div class="offer-details">
        <h3 class="offer-details-title">MES OFFRES LAVAGE LINGE FAMILIAL</h3>
        <p class="offer-details-para">Vêtements - Draps - Serviettes - Chemises</p>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>Offre CLASSIQUE</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + pliage</p>
              <p>5,00 € le kilo si - de 5 KG</p>
              <p>4,50 € le kilo si + de 5 KG</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre haut de gamme !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et plié.</b><br>
                Il ne vous reste plus qu’à le ranger dans votre armoire !
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre PREMIUM</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Repassage</p>
              <p>8,00 € le kilo si - de 5KG</p>
              <p>7,00 € le kilo si + de 5KG</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre sur-mesure !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et repassé.</b><br>
                Il ne vous reste plus qu’à le ranger dans votre armoire !
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>MANTEAU/BLOUSON</h3>
            </div>
            <div class="card-header">
              <p style="margin-bottom: 45px">Lavé, Séché, Plié à partir de 8€</p>
            </div>
            <div class="card-body">
              <p>
                Vous n’avez plus besoin d’attendre à la laverie <br> Déposez votre linge au local ou
                 demandez une collecte/livraison, et il vous est restitué <b>lavé, séché et plié.</b>
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>Offre CHEMISES</h3>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Repassage</p>
              <p>4,00 €/pièce</p>
            </div>
            <div class="card-body">
              <p>
                Économisez votre temps en profitant de cette offre haut de gamme !<br>
                Déposez votre linge au local ou demandez une collecte/livraison, et elles vous sont restituées 
                <b>lavées, séchées, repassées mises sur cintres.</b><br>
                Il ne vous reste plus qu’à les ranger dans votre armoire !
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">MES OFFRES COUETTES/OREILLER</h3>
        <h4>Le saviez-vous ?</h4>
        <p class="offer-details-para">Une couette et des oreillers lavés régulièrement améliorent la qualité de votre sommeil !</p>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>COUETTE SIMPLE (1 place)</h3>
              <h4>10,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Pliage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre couette au local ou demandez une collecte/livraison, et elle vous est 
                restituée <b>lavée, séchée, pliée</b>.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>COUETTE DOUBLE (2 places)</h3>
              <h4>20,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage + Pliage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre couette au local ou demandez une collecte/livraison, et elle vous est
                restituée <b>lavée, séchée, pliée</b>.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>OREILLER</h3>
              <h4>5,00 €</h4>
            </div>
            <div class="card-header">
              <p>Lavage + Séchage</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre oreiller au local ou demandez une collecte/livraison, et il vous est restitué <b>lavé, séché</b>.
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">MES OFFRES REPASSAGE</h3>
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>REPASSAGE LINGE FAMILIAL</h3>
            </div>
            <div class="card-header">
              <p>* Hors chemises/chemisiers</p>
              <p>6,00 € le kilo si - de 5KG</p>
              <p>5,00 € le kilo si + de 5KG</p>
            </div>
            <div class="card-body">
              <p>
                Déposez votre linge dans un sac ou bac à linge au local ou demandez 
                une collecte/livraison et il vous est restitué dans son contenant.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <h3>REPASSAGE CHEMISES</h3>
            </div>
            <div class="card-header">
              <p>Déposez vos chemises propre et sèche</p>
              <p>2,50 €/pièce</p>
            </div>
            <div class="card-body">
              <p>
                Déposez vos chemises sur cintres au local ou demandez une collecte/livraison 
                et elles vous sont restituées sur leurs cintres.
              </p>
            </div>
          </div>
        </div>
        <h3 class="offer-details-title">COLLECTE/LIVRAISON</h3>
        <img src="@/assets/svg/order_delivery.svg" alt="MES OFFRES COLLECTE/LIVRAISON" width="180">
        <div class="cards">
          <div class="card">
            <div class="card-title">
              <h3>Livraison</h3>
              <h4>4,00 €</h4>
            </div>
            <div class="card-header">
              <p>Collecte au jour choisi / disponible.</p>
            </div>
            <div class="card-body">
              <p>
                Montant à ajouter à la prestation choisie.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-bloc">
      <button class="btn-order" @click="$router.push('passer-commande')">PASSER COMMANDE</button>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.home-picture, .home-address, .home-or, .how-to-work, .step, .depot-how-to-work {
  display: flex;
}

.bckgrnd {
  background-image: url('../assets/img/home.jpg');
  background-position: center;
  background-size: cover;
}

.home-picture {
  background-color: rgba(0, 0, 0, 0.6);
}

h1 {
  font-weight: 400;
  margin-top: 50px;
}

p {
  letter-spacing: .6px;
  line-height: 23px;
}

h1, .subtitle, .quality-service {
  text-align: center;
  color: #FFF;
}

.subtitle h2 {
  font-size: 23px;
  font-weight: 600;
  max-width: 900px;
  line-height: 27px;
  letter-spacing: 1px;
}

.home-picture {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home-address {
    margin: 40px 0;
    margin-bottom: 10px;
    
    .bloc-1, .bloc-2 {

      h3 {
        color: #FFF;
      }

      p {
        color: #FFF;
        margin: 3px 0;
      }
    }

    .bloc-1 {
      margin-right: 70px;
    }

    .bloc-2{
      margin-left: 70px;
    }

    .home-or {
      align-items: center;
      font-size: 30px;

      span {
        color: #FFF;
      }
    }
  }

}

.quality-service {
  text-align: center;
  background: #162061;
  color: white;
  padding: 15px;
  margin: auto;
  width: 100%;
  font-weight: 500;
}

.how-to-work {
  margin-top: 25px;
  flex-direction: column;
  align-items: center;

  .how-to-work-2min {
    text-align: center;
    font-size: 18px;
  }

  .step {
    flex-wrap: wrap;
    justify-content: center;
    
    .card {
      width: 350px;
      margin: 20px;
      border-radius: 8px;
      box-shadow: 0 10px 20px #16206115;
      padding-bottom: 20px;

      h4 {
        background-color: #162061;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: #FFF;
        padding: 15px 0;
        text-align: center;
        margin: 0;
        font-size: 18px;
      }

      .img {
        margin: 35px 0;
        display: flex;
        justify-content: center;
      }

      p {
        margin: 10px 0;
        padding: 0 25px;

        b {
          font-size: 20px;
        }
      }

      &:hover {
        transform: scale(1.02);
        cursor: pointer;
      }
    }
  }
}

.why-choose {
  margin: 50px 0;
  
  h2 {
    text-align: center;
    margin-bottom: 80px;
  }
  
  .step {
    display: flex;
    justify-content: space-around;
    max-width: 1400px;
    margin: auto;
    flex-wrap: wrap;

    .card {
      width: 300px;
      margin: 15px;

      .img {
        display: flex;
        justify-content: center;
      }

      h4, p {
        text-align: center;
      }

      h4 {
        font-size: 18px;
      }
    }
  }
}

.depot {
  background-color: rgb(250, 250, 250);
  padding-bottom: 80px;

  .quality-service {
    margin-bottom: 50px;
  }

  h3 {
    text-align: center;
  }

  .depot-how-to-work {
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
    flex-wrap: wrap;

    div {
      width: 290px;
      text-align: center;
      background-color: #caa032;
      padding: 5px 15px;
      border-radius: 3px;
      margin: 15px;

      p {
        color: #FFF;
        font-size: 19px;
      }
    }

  }

  .depot-infos {
    display: flex;
    width: 400px;
    font-size: 19px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    padding: 15px;

    p {
      font-weight: 700;
    }
  }
}

.order-bloc {
  display: flex;
  justify-content: center;
  margin: 40px auto;

  .btn-order {
    border: 0;
    width: 400px;
    padding: 15px 25px;
    background-color: #caa032;
    color: #FFF;
    font-size: 17px;
    cursor: pointer;

    &:hover {
      background-color: #e0b547;
    }
  }
}

/* Responsive */
@media screen and (max-width: 900px) {
  h1 {
    margin: 15px;
    margin-top: 50px;
    margin-bottom: 25px;
    font-size: 20px;
  }

  .subtitle h2 {
    font-size: 18px;
    line-height: 30px;
    margin: 15px;
    font-weight: 400;
  }

  .card {
    width: 310px!important;
  }

  .bloc-1, .bloc-2 {
    width: 240px;
    margin: 0 15px!important;
  }

  .home-or {
    display: none;
  }

  .home-address {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  form {
    margin: 0 5px;

    .bloc-4 {

      .bloc-2 {
       margin-left: 0!important;
 
       label {
         margin-left: 0 !important;
       }
     }
    }
  }

  .depot h3, .how-to-work-2min {
    font-weight: 400;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 450px) {
  .depot-infos {
    flex-direction: column;
    width: 280px!important;
  }
}

/* Price */
.price-bloc-title {

  h2 {
    text-align: center;
    background: #162061;
    color: white;
    padding: 15px;
    margin: auto;
    width: 100%;
    font-weight: 500;
  }
}

.offer-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  .offer-details-title {
      background-color: #caa032;
      color: #FFF;
      padding: 10px 20px;
      border-radius: 3px;
      margin: 30px 10px;
      width: 650px;
      text-align: center;
    }

    .offer-details-para {
      margin: 0 15px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  
  .cards {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    margin-top: 10px;
    flex-wrap: wrap;
    max-width: 1300px;
    
  .card {
    width: 400px;
    box-shadow: 0 10px 20px #16206115;
    text-align: center;
    border-radius: 5px;
    margin: 15px;
    
    .card-title {
      background-color: #162061;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      color: #FFF;
      padding: 10px;
      
      h3 {
        margin-top: 0;
        margin-bottom: 7px;
      }
      
      h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
      }
    }
    
    .premium {
      background-color: #caa032;
    }

    .card-header {
      padding: 10px;
      
      p {
        margin: 0;
      }
    }

    .card-body {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 750px) {
  .offer-details-title {
      width: 350px!important;
    }
}

@media screen and (max-width: 450px) {
  .offer-details-title {
    width: 270px !important;
  }
  
  .card {
    width: 290px!important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
}
</style>