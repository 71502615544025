<template>
    <div class="running">
        <div class="running-bloc-title">
            <h1>COMMENT ÇA MARCHE ?</h1>
            <p>Votre linge est collecté, lavé, séché, plié ou repassé puis livré quand vous le souhaitez !</p>
            <p>Vous souhaitez économiser votre temps et avoir du linge propre ?<br>
            Passez commande en quelques clics et récupérez votre linge en quelques jours.</p>
        </div>
        <div class="steps">
            <div class="step step-1">
                <div class="step-title">
                    <h2>1 - Passez commande en quelques clics</h2>
                </div>
                <img src="@/assets/svg/buy.svg" alt="Passez commande en quelques clics">
                <a href="/passer-commande" ref="noreferrer">Rendez-vous ici pour passer commande</a>
                <p>Indiquez votre adresse de collecte et livraison, sélectionnez votre offre et choisissez un créneau horaire de collecte
                puis le délai choisi de restitution.<br>
                Préparez votre linge dans un sac cabas ou un bac a linge.</p>
            </div>
            <div class="step step-2">
                <div class="step-title">
                    <h2>2 - Confiez moi votre linge en toute simplicité</h2>
                </div>
                <img src="@/assets/svg/simply.svg" alt="Confiez moi votre linge en toute simplicité">
                <p>S’il n’y a pas de sonnette à votre nom, gardez votre téléphone près de vous le jour de la collecte du linge afin que je
                puisse vous contacter au besoin.</p>
            </div>
            <div class="step step-3">
                <div class="step-title">
                    <h2>3 - Profitez d’un service de qualité</h2>
                </div>
                <img src="@/assets/svg/quality_delivery.svg" alt="Profitez d’un service de qualité">
                <p><b>Vous n’avez plus rien à faire !<br></b>
                    Une fois votre linge collecté, je me charge de le laver, le sécher, le plier ou le repasser selon vos instructions.<br>
                    Je traite chaque commande dans des machines individuelles afin d’éviter tout risque de perte ou de mélange de vêtements,
                    pour vous assurer un service réellement personnalisé car chaque client est unique.

                </p>
            </div>
            <div class="step step-4">
                <div class="step-title">
                    <h2>4 - Récupérez votre linge en quelques jours</h2>
                </div>
                <img src="@/assets/svg/finish.svg" alt="Récupérez votre linge en 72 heures maxi jours ouvrés">
                <p>Ma laverie Ad’Orée propose des prestations de laverie et blanchisserie, avec un service de collecte/livraison disponible
                dans un rayon de 10 Km autour de Saint Laurent des Autels.<br>
                Ce service a été conçu pour vous faciliter la tâche et vous faire gagner du temps.
                Il vous suffit de passer commande sur le site, par mail ou téléphone.</p>
                <p>Notre tarification s’effectue au KILO, il vous suffira de préparer votre linge dans un sac cabas ou un bac à linge. Au
                moment de la collecte, je pèserai votre linge avec un pèse bagage très précis.<br>
                Une facture vous sera envoyée par mail ou par SMS avant la livraison afin que vous puissiez anticiper votre règlement
                lors de la livraison. Mode de règlements autorisés CB, Espèces, Virement. Le chèque est accepté mais reste exceptionnel.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.running-bloc-title {

    h1 {
        text-align: center;
        color: #caa033;
        margin-bottom: 50px;
    }

    p {
        text-align: center;
    }
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 10px;

    .step-title {

        h2 {
            background-color: #162061;
            color: #FFF;
            padding: 10px 20px;
            border-radius: 3px;
            margin: 30px 10px;
            width: 650px;
            text-align: center;
        }
    }

    p {
        max-width: 800px;
        text-align: center;
        margin: 10px;
    }
}

img {
    margin: 20px auto;
    width: 200px;
}

/* Responsive */
@media screen and (max-width: 750px) {
    .step-title {

        h2 {
            width: 280px!important;
            font-size: 18px;
            padding: 10px!important;
        }
    }
}
</style>