<template>
  <div class="cancel">
    <h1>Annuler ma commande</h1>
    <button @click="cancelAppointment()">Confirmer l'annulation de ma commande</button>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created() {
        const regex_key = /^[0-9-]{24}$/;

        if (!regex_key.test(this.$route.params.key)) {
            this.$router.push('/');
        }
    },
    methods: {
        cancelAppointment() {
            const regex_key = /^[0-9-]{24}$/;

            if (!regex_key.test(this.$route.params.key)) {
                this.$router.push('/');
            } else {
                this.$store.dispatch('cancelAppointment', this.$route.params.key);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
    margin-bottom: 150px;

    button {
        border: 0;
        padding: 15px;
        margin-top: 50px;
        cursor: pointer;
    }
}
</style>