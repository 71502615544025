<template>
    <nav>
        <el-menu text-color="#162061" active-text-color="#caa032" :default-active="activeIndex" class="el-menu" mode="horizontal">
            <el-menu-item index="0" @click="$router.push('/')">
                <img class="logo-img" src="@/assets/img/LogoLaverie.png" width="175" alt="Logo malaverieadoree.fr">
            </el-menu-item>
            <div class="flex-grow" />
            <el-menu-item index="1" @click="$router.push('/')">Accueil</el-menu-item>
            <el-menu-item index="2" @click="$router.push('/passer-commande')">Passer commande</el-menu-item>
            <!-- <el-menu-item index="3" @click="$router.push('/tarifs')">Tarifs</el-menu-item> -->
            <el-menu-item index="4" @click="$router.push('/professionnels')">Professionnels</el-menu-item>
            <el-menu-item index="5" @click="$router.push('/fonctionnement')">Fonctionnement</el-menu-item>
            <el-menu-item v-if="!isAuth" index="6" @click="$router.push('/connexion')">Espace membre</el-menu-item>
            <el-menu-item v-if="isAuth" index="7" @click="$router.push('/auth/home')">Gestion des commandes</el-menu-item>
            <el-menu-item v-if="isAuth" index="8" @click="disconnect()">Deconnexion</el-menu-item>
        </el-menu>
    </nav>
</template>

<script>

export default {
    data() {
        return {
            activeIndex: null
        }
    },
    computed: {
        isAuth() {
            return this.$store.state.auth;
        }
    },
    methods: {
        disconnect() {
            localStorage.removeItem('token');
            this.$router.push('/');
            this.$store.commit('SET_AUTH', false);
        }
    }
}
</script>

<style scoped lang="scss">
.el-menu {
    height: 75px;

    li:hover, li:focus {
        background-color: white!important;
    }
}

.logo {
    width: 160px;
}

.flex-grow {
    flex-grow: 1;
}
</style>